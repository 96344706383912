@import '../../styles/variables.scss';
@import '../../styles/media-queries.scss';

#Navbar {
    background-color: transparent;

    .navbar-toggler {
        color: $primary;
        border-color: $primary;
        padding: 0.25rem 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        @include gt-sm {
            display: none;
        }
    }

    .navbar-dropdown {
        background: $primary;
        opacity: 0.75;

        li {
            a {
                color: $secondary;
                margin-right: 0.25rem;
            }
        }
        @include gt-sm {
            background: transparent;
            li {
                a {
                    color: $primary;
                    margin-right: 0;
                }
            }
        }
    }

    .nav-link {
        cursor: pointer;
        color: $primary;

        &:hover {
            color: $success;
        }
    }
}
