@import '../../styles/variables.scss';

.portfolio-card {
    border: none !important;
    border-bottom: 2px solid $secondary !important;
    border-radius: 0px !important;

    &:hover {
        border-bottom-color: $success !important;
    }

    .portfolio-card-body {
        cursor: default;

        .card-content {
            color: $primary;
        }
    }

    .card-img-top {
        max-height: 196px;
        border-radius: 0%;
        height: 200px;
    }

    .portfolio-btn {
        color: $secondary !important;
        background-color: $primary;
        border: 1px solid $secondary !important;
        &:hover {
            background-color: $primary !important;
            color: $success !important;
            border-color: $success !important;
        }
    }
}
