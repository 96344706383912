@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Roboto+Mono&display=swap');

@import './styles/variables.scss';

// global theming
#AppContainer {
    min-height: 100vh;
    // background: $primary;
    color: $primary;

    a {
        text-decoration: none;
        color: $primary;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: 'Roboto Mono', monospace;
    }
    p,
    a,
    span {
        font-family: 'Open Sans', sans-serif;
    }

    .cta-btn {
        color: $primary !important;
        background-color: rgba(0, 0, 0, 0);
        border: 1px solid $primary !important;
        border-radius: 0%;
        &:hover {
            background-color: rgba(0, 0, 0, 0) !important;
            color: $success !important;
            border-color: $success !important;
        }
    }

    .main-section {
        margin-top: 56px;
    }
}
