@import '../../styles/variables.scss';

.landing-container {
    height: 100vh;
    #LandingSection {
        .landing-content {
            cursor: default;
            color: $primary;

            .landing-headline-1 {
                animation: 1s ease-in pulse;
            }

            .landing-headline-2 {
                animation: 1.5s ease-in pulse;
            }

            .personal-summary {
                animation: 4s ease-in-out pulse;
            }
        }

        .landing-cta-section {
            max-width: 600px;
            z-index: 1;
        }

        #particle-canvas {
            position: fixed !important;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    25% {
        color: darken($primary, 15%);
        transform: scale(1.025);
    }

    50% {
        color: $primary;
        transform: scale(1);
    }
}
