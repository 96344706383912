// ! bootstrap default color overrides
$primary: #020e17;
$secondary: #76d9b9;
$success: #15ab89;
$info: #d8c13a;
$warning: #ff881d;
$danger: #ef4444;
$light: #fbf5da;

// constants
$navHeight: 56px;
$footerHeight: 34px;
