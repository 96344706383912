@import '../../styles/variables.scss';
// footer styles
#Footer {
    background-color: $primary;
    color: $light;
    opacity: 0.75;
    .footer-text {
        cursor: default;
        font-size: 0.75rem;
        .linkedin-link {
            color: $secondary !important;

            &:hover {
                color: $success !important;
            }
        }
    }
}
